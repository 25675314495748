import React from 'react';

const PrincipalFooterInfo = () => {
    return (
        <div className='container-fluid'>
            <div style={{margin: '0px 20px 40px 20px'}}>
                <div id='principalFooterInfoText' className='col-md-9' style={{margin: '0 auto'}}>
                    Insurance products and plan administrative services provided through
                    Principal Life Insurance Company<sup>®</sup>. Securities offered through Principal Securities, Inc.,
                    <a href='https://www.sipc.org/'> member SIPC </a>
                    and/or independent broker/dealers.
                    Referenced companies are members of the Principal Financial Group<sup>®</sup>, Des Moines, IA 50392.
                    <p>3795204-082024</p>
                </div>
            </div>
        </div>
    );
};

export default PrincipalFooterInfo;
