import React from 'react';
import {useSelector} from 'react-redux';
import {stateSelector} from '../../redux/selectors/StateSelector';
import {GoogleAnalyticsUtil} from '../../util/GoogleAnalyticsUtil';

const ContactUs = () => {

    const formatLabel = (key) => {
        let label = key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .toLowerCase();
        return label.charAt(0).toUpperCase() + label.slice(1);
    };
    const reduxState = useSelector(stateSelector);
    const contactUsHref = 'mailto:BenefitDesignTool@principal.com?subject=Connect me with a financial professional' +
        '&body=Your name: %0A%0AOrganization name: %0A%0APhone: %0A%0AEmail: %0A%0A' +
        'Does your organization currently offer any Principal® products and services?%0A%0A%0A' +
        'Do you already work with a financial professional? If yes, please indicate name and firm name.%0A%0A%0A' +
        'Anything else you would like to share?%0A%0A%0A';

    const complianceFooter = 'Insurance products and plan administrative services provided through Principal Life Insurance Company®.' +
        ' Securities offered through Principal Securities, Inc., member SIPC and/or independent broker/dealers.' +
        ' Referenced companies are members of the Principal Financial Group®, Des Moines, IA 50392.%0A3795204-082024';

    const addData = () => {
        return contactUsHref +
            'Organization Information%0A' + addItem(reduxState.companyInfo) + '%0A%0A%0A' +
            complianceFooter;
    };
    const addItem = (item) => {
        let result = '';
        Object.keys(item).forEach(key => result = result + formatLabel(key) + ': ' + item[key] + '%0A');
        return result;
    };
    return (
        <div>
            <strong>Don’t work with a financial professional? Have questions? We can help!</strong>
            <p>Provide your contact information in this
                <a onClick={() => GoogleAnalyticsUtil.callToAction('email')} href={addData()}> email</a>
                , and we’ll have someone in your area connect with you.
            </p>
        </div>
    );
};

export default ContactUs;
